<template>
  <div class="modal-confirm modal-invitation-cooperation">
    <div class="modal-confirm__body">
      <div class="modal-confirm__title modal-invitation-cooperation__title">
        {{ title }}
      </div>
      <div class="modal-invitation-cooperation__desc">
        {{ text }}
      </div>
    </div>
    <div class="modal-confirm__footer modal-invitation-cooperation__footer">
      <button
        type="button"
        class="btn btn--prime"
        @click="acceptInvite"
      >Согласиться</button>
      <button
        type="button"
        class="btn btn--border"
        @click="rejectInvite"
      >Отказаться</button>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
  props: {
    title: String,
    text: String,
    invite_id: String,
    resolve: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    ...mapActions({
      setInviteStatus: 'invite/setInviteStatus',
      getUser: 'user/getUser'
    }),
    rejectInvite() {
      const id = this.invite_id;
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите отклонить приглашение?'
      })
        .then((res) => {
          if (!res) return;
          this.setInviteStatus({
            id, status: this.$ENUM.USER_INVITE_STATUSES.REJECTED.KEY
          })
            .then(() => {
              this.resolve(true);
            });
        });
    },
    acceptInvite() {
      const id = this.invite_id;
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите принять приглашение?'
      })
        .then((res) => {
          if (!res) return;
          this.setInviteStatus({
            id, status: this.$ENUM.USER_INVITE_STATUSES.JOINED.KEY
          }).then(() => {
            this.$modal.show('Accept', {
              title: 'Поздравляем! Вы стали сотрудником компании.'
            });
            this.getUser();
            this.resolve(false);
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@style/src/component/modal-confirm-styles.scss";
:root {
  --color-prime: #0C76DA;
  --color-prime-active: #0F4578;
}

.theme--red {
  --color-prime: #FF6B69;
  --color-prime-active: #D7221F;
}

.modal-invitation-cooperation {
  width: 100%;
  @include respond-to('md') {
    max-width: 400px;
    //TODO
    margin: auto;
  }
  .modal-invitation-cooperation__body {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(118, 118, 128, 0.2);
    border-radius: 8px;
    margin-bottom: 27px;
    padding: 10px;
  }

  .modal-invitation-cooperation__title {
    font-weight: 600;
    font-size: 19px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }

  .modal-invitation-cooperation__desc {
    font-size: 17px;
    text-align: center;
    color: #B3B3B8;
  }

  .modal-invitation-cooperation__footer {
    padding: 20px 0;
    @media(max-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .btn {
    width: 100%;
    margin: 0 20px 0 20px;
    @media(max-width: 768px) {
      margin: 10px 20px;
    }
  }

  .btn--prime {
    background: var(--color-prime);
    &:hover, &:active {
      background: var(--color-prime-active);
    }
  }
}


</style>
